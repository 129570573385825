// Core
import { useMemo, useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { FormikProps } from 'formik'
// Hooks
import { useInitData } from './use-init-data'
import { useInitialValues } from './use-initial-values'
import { useGetActiveSite } from 'modules/sites'
import { useRevalidate } from 'core/hooks/use-revalidate'
// Services
import { httpService } from 'core/data'
import { EntityService } from '../utils'
// Types
import { EavResourceType, IEntity, SortedAttribute } from '../types'
import { getIdFromIri } from 'core/utils'

type AttributesData = {
  mainAttributes: SortedAttribute[]
  asideAttributes: SortedAttribute[]
}

type Options = {
  queryKey?: string
  onSaveSuccess?: (data?: unknown) => void
}

export const useEntityController = (
  id: any,
  typeId: any,
  resourceType: EavResourceType,
  options: Options = {}
) => {
  const { queryKey, onSaveSuccess } = options
  const activeSite = useGetActiveSite()
  const [entity, setEntity] = useState<IEntity>(null!)
  const isEdit = Boolean(id)

  const formRef = useRef<FormikProps<any>>(null)
  const type = resourceType === EavResourceType.WIDGET ? 'widgets' : 'entity'

  const { getEntityUrls, revalidateEntity, revalidateAll } = useRevalidate()

  const { data, isLoading, isError, refetch, isFetching } = useInitData(id, typeId, type, {
    onSuccess: ({ entity }) => {
      setEntity(entity)
    },
    queryKey,
  })

  const { initialValues, validationSchema } = useInitialValues(entity, data)

  const { mainAttributes, asideAttributes } = useMemo<AttributesData>(() => {
    if (!data?.attributes) return { mainAttributes: [], asideAttributes: [] }

    return data?.attributes.reduce(
      (accum: AttributesData, attribute: any) => {
        if (attribute.attribute.options.show_in_sidebar) {
          accum.asideAttributes.push(attribute)
        } else {
          accum.mainAttributes.push(attribute)
        }
        return accum
      },
      { mainAttributes: [], asideAttributes: [] }
    )
  }, [data?.attributes])

  const mutation = useMutation(
    async (dataParam: any) => {
      if (dataParam.asTemplate) {
        return httpService.post('/entities', dataParam)
      }

      const url = resourceType === EavResourceType.WIDGET ? 'widgets' : 'entities'
      const id = entity?.id
      const method = isEdit ? 'put' : 'post'

      let entityData = null
      const originalId = getIdFromIri(entity.original!)

      if (dataParam.action === 'publish') {
        entityData = await httpService.post(
          `/entities/${originalId}/publishing`,
          EntityService.clearIdsFromEntity(dataParam)
        )
      } else if (dataParam.action === 'unpublish') {
        await EntityService.changeEntityStatus(+originalId, 'draft')
        entityData = await httpService.get(`/entities/${originalId}`)
      } else {
        entityData = await httpService[method](`/${url}${isEdit ? `/${id}` : ''}`, dataParam)
      }

      if (['default', 'publish', 'unpublish'].includes(dataParam.action)) {
        const isGlobalEntity = data?.entityType?.global

        if (isGlobalEntity) {
          await revalidateAll()
        }

        if (!isGlobalEntity && activeSite && isEdit && !entity.template) {
          const enriryUrl = await getEntityUrls(url, entity.originalId, 'entity')

          if (enriryUrl) {
            await revalidateEntity(entity.originalId, 'entity', ...enriryUrl)
          }
        }
      }

      if (dataParam.action === 'preview') {
        EntityService.previewEntity(activeSite!, id)
      }

      return entityData
    },
    {
      onSuccess: onSaveSuccess,
    }
  )

  return {
    entity,
    isLoading,
    isFetching,
    attributes: data?.attributes,
    mainAttributes,
    asideAttributes,
    initialValues,
    validationSchema,
    entityType: data?.entityType,
    formRef,
    mutation,
    setEntity,
    isError,
    refetch,
  }
}
